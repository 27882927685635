@font-face {
    font-family: proxima-nova, sans-serif;
}

p, h1, h2, h3, h4, h5, h6, label, input, select, a, div {
    font-family: proxima-nova, sans-serif !important;
}

.stackable-tab > .ui.grid {
    flex-wrap: wrap;
}

@media only screen and (max-width: 940px) {
    .stackable-tab > .ui.grid > [class*="four wide"].column {
        width: 100% !important;
    }

    .stackable-tab > .ui.grid > [class*="twelve wide"].column {
        width: 100% !important;
    }
}

.scalable {
    transform: scale(1);
    transition: opacity 0.2s, color 0.2s, transform 0.2s;
}

.scalable:hover {
    transform: scale(1.1);
}


.ui.labeled .ui.button {
    border-radius: 0;
}

.ui.labeled .ui.label {
    border-radius: 0;
    border: 2px solid;
}

.whiteShadow .vertical.menu {
    border: none;
    box-shadow: 0 0.625rem 0.875rem -0.3125rem #e0e0e0;
}

.ui.form input[type=text], .ui.form input[type=password], .ui.form input[type=number], .ui.selection.dropdown {
    border-radius: 0;
    border: 1px solid black;
}


.ui.form input[type=text]:focus, .ui.form input[type=password]:focus, .ui.form input[type=number]:focus, .ui.selection.dropdown:focus, .ui.selection.active.dropdown .menu, .ui.selection.active.dropdown:hover .menu, .ui.selection.active.dropdown, .ui.selection.active.dropdown:hover {
    border-radius: 0;
    border-color: #f28f3a;
}

a-scene {
    border: 2px solid black;
    width: 100%;
    min-height: 500px;
}

:root {
    --box-shadow: none;
    --amplify-primary-color: white;
    --amplify-primary-contrast: black;
    --amplify-font-family: proxima-nova, sans-serif;
}


.miniDropdown {
    width: 50px !important;
    padding: 0 !important;
    border: 0 !important;
    line-height: 38px !important;
    height: 10px !important;
    margin-left: auto !important;
}

.miniDropdownTop {
    width: 100px !important;
    padding: 10px 15px !important;
    border: 0 !important;
    line-height: 38px !important;
    height: 10px !important;
    margin-left: auto !important;
}

.miniDropdown .divider.text {
    color: transparent;
}

.miniDropdown .item .text {
    display: none;
}

.avatarRounded img {
    border-radius: 3px !important;
}

@media only screen and (max-width: 780px) {
    .right.menu .dropdown {
        border: none;
    }

    .right.menu .dropdown .divider.text {
        line-height: 1;
        font-size: 0;
        color: transparent;
    }

    .right.menu .dropdown .item .text {
        display: none;
    }

    .modal .content > .ui.grid {
        margin-left: 0 !important;
    }
}

.minWidthContainer {
    min-width: 300px;
    margin-top: 50px;
}

@media (min-width: 780px) and (max-width: 1750px) {
    .ui.container.minWidthContainer {
        margin-left: 200px !important;
        margin-top: 20px;
    }
}

@media (min-width: 1050px) and (max-width: 1330px) {
    .ui.container.minWidthContainer {
        width: 80%;
    }
}
@media (min-width: 780px) and (max-width: 1050px) {
    .ui.container.minWidthContainer {
        width: 70%;
    }
}



/* ANIMATION HOVER */
.hoverContainer .hover {
    transition: all 0.2s ease-in-out;
    position: relative;
}

.hoverContainer .hover:before, .hoverContainer .hover:after {
    content: "";
    position: absolute;
    bottom: -5px;
    width: 0;
    height: 2px;
    margin: 5px 0 0;
    transition: all 0.2s ease-in-out;
    transition-duration: 0.66s;
    opacity: 0;
    background-color: black;
}

.hoverContainer .hover.hover-1:before, .hoverContainer .hover.hover-1:after {
    left: 0;
}

.hoverContainer .hover.hover-2:before, .hoverContainer .hover.hover-2:after {
    right: 0;
}

.hoverContainer .hover.hover-3:before {
    left: 50%;
}

.hoverContainer .hover.hover-3:after {
    right: 50%;
}

.hoverContainer .hover.hover-4:before {
    left: 0;
}

.hoverContainer .hover.hover-4:after {
    right: 0;
}

.hoverContainer:hover {
    cursor: pointer;
}

.hoverContainer:hover .hover:before, .hoverContainer:hover .hover:after {
    width: 100%;
    opacity: 1;
}

.hoverContainer:hover .hover.hover-3:before, .hoverContainer:hover .hover.hover-4:before, .hoverContainer:hover .hover.hover-3:after, .hoverContainer:hover .hover.hover-4:after {
    width: 50%;
}

.item.visible.transition {
    display: flex !important;
}


/*  slide transition */

.pageSlider-exit .page {
    z-index: 1;
}

.pageSlider-enter .page {
    z-index: 10;
}

.pageSlider-enter.left .page {
    opacity: 0;
}

.pageSlider-enter.right .page {
    opacity: 0;
}

.pageSlider-exit.left .page {
    opacity: 0;
}

.pageSlider-exit.right .page {
    opacity: 0;
}

.pageSlider-enter.pageSlider-enter-active .page {
    opacity: 1;
    transition: all 800ms ease-in-out;
}

.fitContent{
    width: fit-content;
    width:  -moz-fit-content;
}
